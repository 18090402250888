import React, { useState } from "react";
import { useAuth0 } from "../hooks/useAuth0";
import useSWR from "swr";
import api from "../utils/api";
import DownIcon from "../components/UI/Icons/DownIcon";
import UpIcon from "../components/UI/Icons/UpIcon";
import { LoadingSpinner } from "../components/UI/Loading";

const AudienceDetails = ({audience, customerId, setEditing}: any) => {
  const { token } = useAuth0();

  const { data, mutate } = useSWR<{ eventList: any, nextToken: string }>(`/ssc/customers/${customerId}/audienceSelections/${audience.id}/events`);
  const { data: versions  } = useSWR<{ audienceList: any, nextToken: string }>(`/ssc/customers/${customerId}/audienceSelections/${audience.id}/versions`);
  const [openIndex, setOpenIndex] = useState(-1);
  const isProd = window.location.hostname.indexOf("ssc.evive.io") > -1;

  const generateFile = () => {
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    api.post(`/ssc/customers/${customerId}/audienceSelections/${audience.id}/generateFile`, {}, {headers: headers})
      .then(response => {
        mutate();
      })
      .catch(error => {})
  };

  const downloadFile = (event: any) => {
    const headers = {
      "Authorization": `Bearer ${token}`,
    };
    if (isProd) {
      api.get(`https://fpa9plqmhk.execute-api.us-east-1.amazonaws.com/Prod/customers/${customerId}/audienceEvents/${event.id}/download`, {headers: headers, responseType: "blob"})
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${event.name}.csv`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {})
    }
  };

  const printTags = (statements: any) => {
    let tagLine = "[";
    statements.forEach((statement: any, index: number) => {
      tagLine = tagLine.concat("{")
      Object.keys(statement).forEach((key: any, tagIndex: number) => {
        tagLine = tagLine.concat(`${key}: ${statement[key]}`)
        if (tagIndex !== Object.keys(statement).length - 1) tagLine = tagLine.concat(", ")
      })
      if (index !== statements.length - 1) tagLine = tagLine.concat("}, ")
      else tagLine = tagLine.concat("}")
    })
    tagLine = tagLine.concat("]")
    return tagLine;
  }

  const mapEvents = () => {
    if(!data) return <div className="h-50 flex justify-center"><LoadingSpinner className="" /></div>;
    if (!data?.eventList.length || !(versions?.audienceList.length)) return <p>No files generated yet for this audience</p>;

    return <>
      <div className="flex items-center">
        <p className="font-2xl">Latest user lists</p>
        <button onClick={() => mutate()} className="ml-4 p-3 rounded border border-gray-300 text-xs">Refresh</button>
      </div>
      <div className="p-8 rounded border border-gray-300 bg-gray-100 mt-3 p-3">
        <div className="flex mb-5">
          <p className="flex-4 text-center">Audience name</p>
          <p className="flex-2 text-center">Generated on</p>
          <p className="flex-2 text-center">User count</p>
          <p className="flex-2 text-center">Status</p>
          <div className="flex-1" />
        </div>
        {data?.eventList.sort((a: any, b: any) => (new Date(b.createdOn).getTime()) - (new Date(a.createdOn).getTime())).map((event: any, index: number) => {
          const version = versions?.audienceList.find((item: any) => item.version === event.audienceVersion);
          return (
            <div onClick={() => setOpenIndex(openIndex === index ? -1 : index)} className={`${openIndex === index ? "bg-white" : "bg-gray-50"} p-3 mb-3 rounded border border-gray-300 hover:cursor-pointer`} key={event.id}>
              <div className="flex justify-between leading-7">
                <p className="flex-4 text-primary-500 underline">{event.name}</p>
                <p className="flex-2 text-center">{new Date(event.createdOn).toLocaleDateString()}, {new Date(event.createdOn).toLocaleTimeString()}</p>
                <p className="flex-2 text-center">{event.memberCount ?? "-"}</p>
                <p className="flex-2 text-center">{event.status}</p>
                <div className="flex-1 flex justify-end">{openIndex === index ? <UpIcon height={22.3} width={22.3} /> : <DownIcon height={22.3} width={22.3} />}</div>
              </div>
              {openIndex === index && <div>
                <p className="mt-4"><strong>Description:</strong> {version.description ?? "N/A"}</p>
                <p><strong>Tags:</strong> {printTags(version.audiences)}</p>
                <p><strong>Generated By:</strong> {event.createdBy}, {new Date(event.createdOn).toLocaleDateString()}</p>
                {event.status === "DONE" && <div className="flex">
                  <button className={`p-4 mt-5 w-28 bg-primary-500 text-white font-bold rounded hover:bg-primary-700 active:bg-primary-800`} disabled={!isProd} onClick={(e) => {e.stopPropagation(); downloadFile(event);}}>Download</button>
                  {parseInt(event.audienceVersion.substring(1)) < parseInt(audience.version.substring(1)) && <p className="ml-4 py-8 text-warning">Warning, this audience is not the latest version</p>}
                </div>}
              </div>}
            </div>
        )})}
      </div>
    </>
  };

  return (
    <div className="mt-21 px-20 py-16" style={{"width": "calc(100vw - 384px)"}}>
      <div className="rounded border border-gray-300 bg-white mt-3 p-5 text-black">
        <p className="text-xl"><strong>Summary</strong></p>
        <p><strong>Audience Name: {audience.name ?? "Untitled"}</strong></p>
        <p><strong>Description:</strong> {audience.description ?? "N/A"}</p>
        <p><strong>Tags:</strong> {printTags(audience.audiences)}</p>
        <p><strong>Created By:</strong> {audience.createdBy}</p>
        <p><strong>Created On:</strong> {new Date(audience.createdOn).toDateString()}</p>
        <div className="flex justify-center">
          <button className="p-4 mt-5 mr-4 font-bold bg-gray-100 text-base text-primary-500 rounded border border-gray-300 hover:bg-white active:bg-gray-100 active:text-primary-800" onClick={() => setEditing("edit")}>Edit audience definition</button>
          <button className="p-4 mt-5 mr-4 font-bold bg-gray-100 text-base text-primary-500 rounded border border-gray-300 hover:bg-white active:bg-gray-100 active:text-primary-800" onClick={() => setEditing("copy")}>Copy audience definition</button>
          <button className="p-4 mt-5 bg-primary-500 text-white font-bold rounded hover:bg-primary-700 active:bg-primary-800" onClick={() => generateFile()}>Get matching users</button>
        </div>
      </div>
      <div className="mt-5 text-black">
        {mapEvents()}
      </div>
    </div>
  );

}

export default AudienceDetails;