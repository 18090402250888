import { HTMLMotionProps, motion, Variants } from "framer-motion";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { useReducedMotion } from "../../../hooks/useReducedMotion";
import { cx } from "../../../utils/cx";
import { LoadingSpinner } from "../Loading";
import style from "./Dropdown.module.scss";

const DropdownButtonIcon: ForwardRefRenderFunction<HTMLDivElement, HTMLMotionProps<"div"> & { isOpen: boolean, isLoading: boolean }> = ({ isOpen, isLoading, ...props }, ref) => {
    const reducedMotion = useReducedMotion();

    const classNames = cx(
        "absolute rounded-tr border border-transparent focus:outline-none focus:border-primary-500 px-6 py-4 h-full pointer-events-none",
        style.icon__position
    );

    const variants: Variants = {
        open: {
            borderBottomRightRadius: "0px",
            transition: {
                duration: reducedMotion ? 0 : .175
            }
        },
        closed: {
            borderBottomRightRadius: "10px",
            transition: {
                delay: reducedMotion ? 0 : .1,
                duration: reducedMotion ? 0 : .1
            }
        }
    };

    const iconVariant: Variants = {
        open: {
            rotate: -180,
            transition: { damping: 300, velocity: 50 }
        },
        closed: {
            rotate: 0,
            transition: { damping: 300, velocity: 50 }
        }
    };

    const animateState = isOpen ? "open" : "closed";

    return (
        <motion.div initial="closed" animate={animateState} variants={variants} ref={ref} className={classNames} {...props}>
            {
                isLoading ? (
                    <LoadingSpinner className="w-6 h-6" />
                ) : (
                        <motion.svg aria-label="dropdownIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="text-gray-900 w-6 h-6" animate={animateState} variants={iconVariant} style={{ originX: "50%", originY: "50%" }}>
                            <path className="fill-current" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                        </motion.svg>
                    )
            }
        </motion.div>
    );
};

export default forwardRef(DropdownButtonIcon);
