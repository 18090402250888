import React from "react";
import { useAuth0 } from "../hooks/useAuth0";

const Login = () => {
  const { login } = useAuth0();

  return (
    <div className="flex justify-center align-center flex-col p-8">
      <button className="bg-gray-50 text-primary-500 font-extrabold sm:ml-auto px-4 py-3 border border-gray-300 rounded hover:bg-gray-100 active:text-primary-700 focus:underline focus:outline-none focus:border-primary-500" onClick={() => login({appState: { targetUrl: "/" } })}>Authenticate</button>
      <p className="text-center mt-40">Please sign in to access the Self Service Tool</p>
    </div>
  );
};

export default Login;
