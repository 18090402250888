export function getAddress() {
  if (process.env.NODE_ENV === "development") {
      return "https://campaigns.dev.evive.io";
  }

  if (window.location.hostname.indexOf("dev") > -1) return "https://campaigns.dev.evive.io";
  if (window.location.hostname.indexOf("uat") > -1) return "https://campaigns.uat.evive.io";

  return "https://campaigns.evive.io";
};
