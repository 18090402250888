import { HTMLMotionProps, motion, Variants } from "framer-motion";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { useReducedMotion } from "../../../hooks/useReducedMotion";
import { cx } from "../../../utils/cx";
import style from "./Dropdown.module.scss";

type DropdownButtonProps = {
    selectedItem: any,
    isOpen: boolean;
    isLoading: boolean;
} & HTMLMotionProps<"input">;

const DropdownButton: ForwardRefRenderFunction<HTMLButtonElement, HTMLMotionProps<"button"> & DropdownButtonProps> = ({ isOpen, selectedItem, isLoading, className = "", ...props }, ref) => {
    const reducedMotion = useReducedMotion();

    const variants: Variants = {
        open: {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            transition: {
                duration: reducedMotion ? 0 : .175
            }
        },
        closed: {
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            transition: {
                delay: reducedMotion ? 0 : .1,
                duration: reducedMotion ? 0 : .1
            }
        }
    };

    const animateState = isOpen ? "open" : "closed";

    const classNames = cx(
        `${selectedItem !== "" || isOpen ? "bg-white" : "bg-gray-100"} w-full rounded border border-gray-300 p-4 pr-16 placeholder-gray-900 text-black font-extrabold disabled:bg-gray-100 focus:outline-none focus:outline-border-primary-500 min-h-54 text-left`,
        style.input,
        className
    );

    return <motion.button
        type="button"
        disabled={isLoading}
        ref={ref}
        initial="closed"
        animate={animateState}
        variants={variants}
        className={classNames}
        {...props}
    >
        {typeof selectedItem !== "string" ? selectedItem.name : selectedItem}
    </motion.button>;
};

export default forwardRef(DropdownButton);
