import React from "react";

const DownIcon: React.FC<{ 
  height?: number,
  width?: number
}> = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 16 10" fill="none">
    <path d="M13.5358 1.19107L8.0223 6.78167L2.46002 1.19107C2.0209 0.745799 1.33781 0.745799 0.898681 1.19107C0.459554 1.63634 0.459554 2.32898 0.898681 2.77425L7.09526 9.05749C7.63197 9.55223 8.41264 9.55223 8.90056 9.05749L15.0971 2.77425C15.5363 2.32898 15.5363 1.63634 15.0971 1.19107C14.658 0.745799 13.9749 0.745799 13.5358 1.19107Z" fill="#505664"></path>
  </svg>
);

export default DownIcon;