import classNames from "classnames";
import { HTMLMotionProps, motion, Variants } from "framer-motion";
import React, { FunctionComponent } from "react";
import { useReducedMotion } from "../../../hooks/useReducedMotion";

type DropdownLabelProps = {
    inputLength: number;
} & HTMLMotionProps<"label">;

const DropdownLabel: FunctionComponent<DropdownLabelProps> = ({ children, inputLength, ...props }) => {
    const reducedMotion = useReducedMotion();

    const variants: Variants = {
        filled: {
            y: "-1.75rem",
            x: "0rem",
            transition: {
                duration: .300
            }
        },
        empty: {
            y: reducedMotion ? "-1.75rem" : "1rem",
            x: reducedMotion ? "0rem" : "1rem",
            transition: {
                duration: .300
            }
        }
    };

    const className = classNames(
        "absolute",
        {
            "cursor-default": reducedMotion || inputLength,
            "select-none cursor-text": !reducedMotion && !inputLength
        }
    );

    const animateState = inputLength ? "filled" : "empty";

    return (
        <motion.label
            animate={animateState}
            variants={variants}
            initial="empty"
            className={className}
            {...props}
        >
            {children}
        </motion.label>
    );
};

export default DropdownLabel;
