import { LocationDescriptorObject } from "history";
import history from "./history";

export const onRedirectCallback = (appState: any) => {
    let locationObject: LocationDescriptorObject = {
        pathname: appState.targetUrl ?? window.location.pathname
    };

    history.push(locationObject);
};
