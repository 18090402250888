import React, { FunctionComponent, HTMLAttributes } from "react";
import { motion } from "framer-motion";
import { cx } from "../../../utils/cx";

export const LoadingSpinner: FunctionComponent<HTMLAttributes<SVGElement>> = ({ color = "text-gray-900", className = "" }) => {
    const animate = {
        rotate: [-360, 360],
        transition: {
            loop: Infinity,
            duration: 1.5,
            delay: 0
        }
    };

    return (
        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={cx("h-full", color, className)} animate={animate} style={{ originX: "50%", originY: "50%" }}>
            <path
                className="fill-current"
                d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
            />
        </motion.svg>
    );
};
