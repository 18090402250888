import React, { useState } from "react";
import Dropdown from "../components/UI/MultiSelectDropdown";
import { LoadingSpinner } from "../components/UI/Loading";
import TagLibraryModal from "./TagLibraryModal";

const AudienceMultiSelect = ({tagCatalogue, allTags, initialTags, saveAudience, cancel, saving}: any) => {

  const initialTagState = {
    tags: initialTags.length ? initialTags : [] as any,
    tagLogic: initialTags.length ? Array(initialTags.length).fill(true) : [] as any,
    statements: initialTags.length ? initialTags.length : 0
  };
  
  const [tagState, setTagState] = useState(initialTagState);
  const [activeIndex, setActiveIndex] = useState(initialTags.length ? initialTags.length - 1  : -1);
  const [option, setOption] = useState(initialTags.length ? "one of" : "all");
  const [showTagLibrary, setModalVisibility] = useState(false);
  
  const addTags = (tags: any) => {
    if (!tags || !tags.length) return;

    if (tagState.tags.length === 0) {
      setTagState({
        tags: [tags],
        tagLogic: [true],
        statements: 1
      })
      setActiveIndex(0);
    } else {
      tags.forEach((tag: any) => {
        let newTags = tagState.tags;
        newTags[activeIndex].push(tag)
        setTagState({
          ...tagState,
          tags: newTags
        })
      });
    }
  }

  const getHeading = () => {
    return (
      <>
        <div className={`mb-2 bg-gray-50 rounded ${tagState.statements > 0 ? "border border-gray-300" : ""}`}>
          <div className="py-3 px-5 flex items-center font-bold">
            Set audience to match <div className="w-40 px-2"><Dropdown data={["all", "one of"]} initialSelectedItem={option} placeholder="" id="" onChange={(option: any) => setOption(option)} /></div> of the following conditions:
          </div>
          {tagState.statements < 1 && <div className="bg-gray-100 rounded-b flex flex-col justify-center items-center">
            <div className="bg-gray-100 mt-7 rounded-b font-normal">
              Select a few tags from the library to define your audience.
            </div>
            <button className="p-4 mt-5 mb-8 text-white bg-primary-500 font-bold rounded" onClick={() => setModalVisibility(true)}>Browse tag library</button>
          </div>}
        </div>
      </>
    );
  };

  const deleteRuleset = (index: number) => {
    let newTags = tagState.tags;
    let newTagLogic = tagState.tagLogic;
    newTags.splice(index,1);
    newTagLogic.splice(index,1);
    setTagState({
      ...tagState,
      tags: newTags,
      tagLogic: newTagLogic,
      statements: tagState.statements - 1
    })
    if (activeIndex >= index) setActiveIndex(activeIndex - 1);
  }

  const deleteRule = (index: number, tagIndex: number) => {
    let newTags = tagState.tags;
    newTags[index].splice(tagIndex,1);
    setTagState({
      ...tagState,
      tags: newTags
    })
  }

  const updateLogic = (value: any, index: number) => {
    let newTagLogic = tagState.tagLogic;
    if (value === "all") {
      newTagLogic[index] = true;
    } else {
      newTagLogic[index] = false;
    }
    
    setTagState({
      ...tagState,
      tagLogic: newTagLogic
    })
  }

  const updateValue = (value: any, index: number, tagIndex: number, type: string) => {
    let newTags = tagState.tags;
    if (type === "boolean") {
      if (value === "is") {
        newTags[index][tagIndex].value = true;
      } else {
        newTags[index][tagIndex].value = false;
      }
    } else {
      newTags[index][tagIndex].value = value
    }
    setTagState({
      ...tagState,
      tags: newTags
    })
  }

  const getRule = (tag: any, index: number, tagIndex: number) => {
    if (tag.valueType === "boolean") {
      return (
        <>
          <div className="w-96 pr-2"><Dropdown active={index === activeIndex} data={[tag.name]} initialSelectedItem={tag.name} placeholder="" id={`${tag.id}`} /></div>
          <div className="w-56 pr-2"><Dropdown active={index === activeIndex} data={["is", "is not"]} initialSelectedItem={tag.value === undefined || tag.value ? "is" : "is not"} placeholder="" id="" onChange={(value: any) => updateValue(value, index, tagIndex, "boolean") } /></div>
          <div className="w-64 pr-2"><input className={`w-full rounded font-extrabold px-4-1/2 py-4 border border-gray-300 ${index === activeIndex ? "bg-white" : "bg-gray-100 hover:bg-gray-100"}`} value={tag.strategy} type="text" id="string" readOnly /></div>
          <span className="ml-2 text-red text-xs hover:cursor-pointer" onClick={() => {deleteRule(index,tagIndex)}}>Delete</span>
        </>
      )
    } else if (tag.valueType === "number") {
      return (
        <>
          <div className="w-96 pr-2"><Dropdown active={index === activeIndex} data={[tag.name]} initialSelectedItem={tag.name} placeholder="" id={`${tag.id}`} /></div>
          <div className="w-56 pr-2"><Dropdown active={index === activeIndex} data={[`${tag.strategy} greater than`, `${tag.strategy} equal to`, `${tag.strategy} less than`]} initialSelectedItem={`${tag.strategy} equal to`} placeholder="" id="" /></div>
          <div className="w-64 pr-2"><input onChange={(event) => updateValue(event.target.value, index, tagIndex, "number") } value={tagState.tags[index][tagIndex].value ?? 0} className={`w-full rounded font-extrabold px-4-1/2 py-4 border border-gray-300 ${index === activeIndex ? "bg-white" : "bg-gray-100 hover:bg-gray-100"}`} type="number" id="string" /></div>
          <span className="ml-2 text-red text-xs hover:cursor-pointer" onClick={() => {deleteRule(index,tagIndex)}}>Delete</span>
        </>
      )
    } else if (tag.valueType === "string") {
      return (
        <>
          <div className="w-96 pr-2"><Dropdown active={index === activeIndex} data={[tag.name]} initialSelectedItem={tag.name} placeholder="" id={`${tag.id}`} /></div>
          <div className="w-56 pr-2"><Dropdown active={index === activeIndex} data={[tag.strategy]} initialSelectedItem={tag.strategy} placeholder="" id="" /></div>
          <div className="w-64 pr-2"><input onChange={(event) => updateValue(event.target.value, index, tagIndex, "string") } value={tagState.tags[index][tagIndex].value ?? ""} className={`w-full rounded font-extrabold px-4-1/2 py-4 border border-gray-300 ${index === activeIndex ? "bg-white" : "bg-gray-100 hover:bg-gray-100"}`} type="text" id="string" /></div>
          <span className="ml-2 text-red text-xs hover:cursor-pointer" onClick={() => {deleteRule(index,tagIndex)}}>Delete</span>
        </>
      )
    }
  }

  const getRuleset = (index: number) => (
    <div className="font-bold">
      <p>Rule set <span className="ml-2 text-red text-xs hover:cursor-pointer" onClick={() => {deleteRuleset(index)}}>Delete ruleset</span>{index !== activeIndex && <span className="ml-2 text-primary-500 text-xs hover:cursor-pointer" onClick={() => setActiveIndex(index)}>Edit ruleset</span>}</p>
      {!tagState.tags[index].length
        ? <div className="mt-5 font-normal flex justify-center items-center">
            Select a few tags from the library to define your audience.
          </div>
        : tagState.tags[index].map((tag: any, tagIndex: number) => 
          <div className="mt-3 flex items-center">
            {getRule(tag, index, tagIndex)}
          </div>
        )}
      {activeIndex === index && <button className="p-4 mt-6 bg-gray-100 text-primary-500 border border-gray-300 font-bold rounded" onClick={() => setModalVisibility(true)}>Add tags</button>}
    </div>
  );

  const getSelections = () => {
    if (tagState.statements === 0) return;

    return (
      <>
        {tagState.tags.map((tag: any, index: any) => {
            return (
              <>
              <div className={`px-10 py-6 ${index === activeIndex ? "bg-white" : "bg-gray-100"} flex flex-col border border-gray-300 rounded`} key={`${index}`}>
                <div className="flex items-center font-bold mb-2">
                  Match <div className="w-40 px-2"><Dropdown onChange={(value: any) => updateLogic(value, index) } active={index === activeIndex} data={["all", "one of"]} initialSelectedItem={"all"} placeholder="" id="" /></div> of the following conditions:
                </div>
                {getRuleset(index)}
              </div>
              {index < tagState.tags.length - 1 && <div className="text-center my-2 font-bold">{option === "all" ? "AND" : "OR"}</div>}
              </>
            )
          })

        }
      </>
    );
  };

  const saveMultiAudience = () => {
    let audience = [] as any;
    if (option === "all") {
      let statements = [{}];
      tagState.tags.forEach((ruleset: any, index: number) => {
        if (tagState.tagLogic[index]) {
          statements.forEach((statement: any) => {
            ruleset.forEach((rule: any) => {
              if (rule.valueType === "boolean") statement[rule.id] = rule.value != null ? rule.value : true;
              if (rule.valueType === "number") statement[rule.id] = rule.value ? rule.value : 0;
              if (rule.valueType === "string") statement[rule.id] = rule.value ? rule.value : "";
            })
          })
        } else {
          let newStatements = [] as any;
          statements.forEach((statement: any) => {
            ruleset.forEach((rule: any) => {
              let statementCopy = JSON.parse(JSON.stringify(statement));
              if (rule.valueType === "boolean") statementCopy[rule.id] = rule.value != null ? rule.value : true;
              if (rule.valueType === "number") statementCopy[rule.id] = rule.value ? rule.value : 0;
              if (rule.valueType === "string") statementCopy[rule.id] = rule.value ? rule.value : "";
              newStatements.push(statementCopy)
            })
          })
          statements = newStatements;
        }
      })
      audience = statements;
    } else {
      tagState.tags.forEach((ruleset: any, index: number) => {
        if (tagState.tagLogic[index]) {
          let statement = {} as any;
          ruleset.forEach((rule: any) => {
            if (rule.valueType === "boolean") statement[rule.id] = rule.value  != null ? rule.value : true;
            if (rule.valueType === "number") statement[rule.id] = rule.value ? rule.value : 0;
            if (rule.valueType === "string") statement[rule.id] = rule.value ? rule.value : "";
          })
          audience.push(statement);
        } else {
          ruleset.forEach((rule: any) => {
            if (rule.valueType === "boolean") audience.push({[rule.id]: rule.value  != null ? rule.value : true})
            if (rule.valueType === "number") audience.push({[rule.id]: rule.value ? rule.value : 0})
            if (rule.valueType === "string") audience.push({[rule.id]: rule.value ? rule.value : ""})
          })
        }
      })
    }
    saveAudience(audience);
  }

  return (
    <>
      <div className="mt-21 px-20 py-16 flex flex-col" style={{"width": "calc(100vw - 384px)"}}>
        {getHeading()}
        {getSelections()}
        {tagState.statements > 0 && <button className="p-4 mt-5 w-36 bg-gray-100 text-primary-500 font-bold border border-gray-300 rounded" onClick={() => {setTagState({tags: tagState.tags.concat([[]]), tagLogic:tagState.tagLogic.concat([true]), statements: tagState.statements+1}); setActiveIndex(activeIndex+1)}}>New rule set</button>}
        <div className="flex">
          {tagState.statements > 0 && <button className="p-4 mr-4 mt-5 w-24 text-white bg-primary-500 font-bold rounded flex items-center justify-center" onClick={() => saveMultiAudience()}>{saving ? <div className="h-6"><LoadingSpinner color="white" /></div> : "Save"}</button>}
          <button className="p-4 mt-5 w-24 text-primary-500 bg-white font-bold rounded" onClick={() => cancel()}>Cancel</button>
        </div>
      </div>
      <TagLibraryModal tagCatalogue={tagCatalogue} tags={allTags} showModal={showTagLibrary} trigger={() => setModalVisibility(false)} onSubmit={addTags} />
    </>
  )

}

export default AudienceMultiSelect;
