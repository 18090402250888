import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from './contexts/Auth0';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { onRedirectCallback } from './utils/redirectCallback';

(async () => {

    let AUTH0_DOMAIN;
    let AUTH0_CLIENT_ID;
    let AUTH0_SCOPE;
    let AUTH0_AUDIENCE;

    if (process.env.NODE_ENV === "development" || window.location.hostname.indexOf("dev") > -1 || window.location.hostname.indexOf("uat") > -1) {
        ({
            REACT_APP_EVIVE_CARE_AUTH0_DOMAIN: AUTH0_DOMAIN,
            REACT_APP_EVIVE_CARE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
            REACT_APP_EVIVE_CARE_AUTH0_SCOPE: AUTH0_SCOPE,
            REACT_APP_EVIVE_CARE_AUTH0_AUDIENCE: AUTH0_AUDIENCE
        } = process.env);
    } else {
        ({
            REACT_APP_EVIVE_CARE_AUTH0_PROD_DOMAIN: AUTH0_DOMAIN,
            REACT_APP_EVIVE_CARE_AUTH0_PROD_CLIENT_ID: AUTH0_CLIENT_ID,
            REACT_APP_EVIVE_CARE_AUTH0_PROD_SCOPE: AUTH0_SCOPE,
            REACT_APP_EVIVE_CARE_AUTH0_PROD_AUDIENCE: AUTH0_AUDIENCE
        } = process.env);
    }

    ReactDOM.render(
        <StrictMode>
            <Auth0Provider
                domain={AUTH0_DOMAIN!}
                client_id={AUTH0_CLIENT_ID!}
                onRedirectCallback={onRedirectCallback}
                scope={AUTH0_SCOPE}
                audience={AUTH0_AUDIENCE}
                redirect_uri={window.location.origin}
            >
                <App />
            </Auth0Provider>
        </StrictMode>,
        document.getElementById("root")
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
