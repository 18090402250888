import axios from "axios";
import { getAddress } from "./environment";

const setHeader = (header: any, value: any) => {
    api.defaults.headers.common[header] = value;
}

const api = {
    ...axios.create({
        baseURL: getAddress(),
    }),
    setHeader
};

api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.common["x-api-key"] = process.env.REACT_APP_EVIVE_CARE_API_KEY;

api.interceptors.request.use(
    (config) => {
        config.withCredentials = false;

        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        const error = new Error(String(response.status));
        (error as any).response = response;
        throw error;
    },
    (error) => Promise.reject(error)
);

export const CancelToken = axios.CancelToken;

export const SWRFetcher = (url: string) => {
    return api.get(url).then(({ data }) => data);
};

export default api;
