import { HTMLMotionProps, motion, Variants } from "framer-motion";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { useReducedMotion } from "../../../hooks/useReducedMotion";

type DropdownOptionsProps = {
    isOpen: boolean
} & HTMLMotionProps<"div">;

const DropdownOptions: ForwardRefRenderFunction<HTMLDivElement, DropdownOptionsProps> = ({ children, isOpen }, ref) => {
    const reducedMotion = useReducedMotion();

    const variants: Variants = {
        open: {
            scaleY: 1,
            opacity: 1,
            transition: {
                delay: reducedMotion ? 0 : .175,
                duration: reducedMotion ? 0 : .175
            }
        },
        closed: {
            scaleY: 0,
            opacity: 0,
            transition: {
                duration: reducedMotion ? 0 : .1
            }
        }
    };

    const animateState = isOpen ? "open" : "closed";

    return (
        <motion.div
            ref={ref}
            initial="closed"
            animate={animateState}
            variants={variants}
            style={{ originY: "-1px" }}
            className="overflow-hidden absolute w-full bg-gray-100 mb-4 rounded-b border border-t-0 border-gray-300 shadow z-40"
        >
            {children}
        </motion.div>
    );
};

export default forwardRef(DropdownOptions);
