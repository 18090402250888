import React, { createContext, FC, useEffect, useState } from "react";
import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";
import createAuth0Client from "@auth0/auth0-spa-js";
import api from "../utils/api";

type Auth0ContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isPopupOpen: boolean;
  clients: string;
  token: string;
  loginWithPopup: Auth0Client["loginWithPopup"];
  handleRedirectCallback: any;
  getIdTokenClaims: Auth0Client["getIdTokenClaims"];
  login: any;
  getTokenSilently: Auth0Client["getTokenSilently"];
  getTokenWithPopup: Auth0Client["getTokenWithPopup"];
  logout: Auth0Client["logout"];
};

export const Auth0Context = createContext<Auth0ContextType>({} as Auth0ContextType);

type Auth0ProviderProps = {
    onRedirectCallback: (appState: any) => any;
} & Auth0ClientOptions;

export const Auth0Provider: FC<Auth0ProviderProps> = ({ children, onRedirectCallback, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth0Client, setAuth0] = useState({} as Auth0Client);
  const [isLoading, setLoading] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [clients, setClients] = useState("");
  const [token, setToken] = useState("");

  const getToken = async (auth0FromHook: any) => {
    if (process.env.NODE_ENV === "development" || window.location.hostname.indexOf("dev") > -1) {
      api.post("/ssc-mock/token", {"customerIDs": "123 456 777 1000"})
        .then(response => {
          const token = response.data.token;
          localStorage.setItem("auth0token", token);
          let base64Url = token.split('.')[1];
          let base64 = base64Url.replace('-', '+').replace('_', '/');
          let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
          setToken(token);
          api.setHeader("Authorization", `Bearer ${token}`);
          setClients(decodedData["https://evive.io/customer_id"]);
          setIsAuthenticated(true);
          setLoading(false);
        })
        .catch(error => {})
    } else {
      if (!auth0FromHook) return;
      const token = await auth0FromHook.getTokenSilently();
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');
      let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
      setToken(token);
      api.setHeader("Authorization", `Bearer ${token}`);
      setClients(decodedData["https://evive.io/customer_id"]);
      setIsAuthenticated(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const initAuth0 = async () => {
      //@ts-ignore
      if(window.Cypress && (process.env.NODE_ENV === "development" || window.location.hostname.indexOf("dev") > -1 )){initOptions.cacheLocation = "localstorage"};
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        setIsAuthenticated(true);
        onRedirectCallback(appState);
      }

      const authenticated = (process.env.NODE_ENV === "development" || window.location.hostname.indexOf("dev") > -1) ? (!!localStorage.getItem("auth0token")) : await auth0FromHook.isAuthenticated();
      setIsAuthenticated(authenticated);
      
      if (authenticated) {
        await getToken(auth0FromHook);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    setLoading(false);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("auth0token");
    auth0Client.logout({
      returnTo: initOptions.redirect_uri
    });
  }

  const login = (...p: any) => {
    if (process.env.NODE_ENV === "development" || window.location.hostname.indexOf("dev") > -1 ) {
      getToken(auth0Client);
    } else {
      auth0Client.loginWithRedirect(...p)
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        handleRedirectCallback,
        clients,
        token,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        login,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
