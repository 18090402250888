import { AnimatePresence, motion, Variants } from "framer-motion";
import React, { FunctionComponent, ReactNode, useCallback, useEffect } from "react";
import { useReducedMotion } from "../../../hooks/useReducedMotion";
import { KeyCodes } from "../../../utils/keyCodes";
import Portal from "../../Portal";
import Container, { ContainerProps } from "../Container";

type ModalProps = {
    isOpen: boolean;
    trigger: () => void;
    children: ReactNode;
    widths?: ContainerProps["width"];
};

const Modal: FunctionComponent<ModalProps> = ({ children, trigger, widths = 6, isOpen = false }) => {
    const reducedMotion = useReducedMotion();

    const onKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.keyCode === KeyCodes.ESCAPE) {
            trigger();
        }
    }, [trigger]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("keydown", onKeyDown);
            document.body.classList.add("overflow-hidden");
        }

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen, onKeyDown]);

    if (!isOpen) {
        return null;
    }

    const backdropVariants: Variants = {
        opened: {
            opacity: 1
        },
        closed: {
            opacity: 0
        }
    };

    const modalVariants: Variants = {
        opened: {
            opacity: 1,
            y: "0rem"
        },
        closed: {
            opacity: 0,
            y: reducedMotion ? "0rem" : "2rem"
        }
    };

    return (
        <Portal>
            <AnimatePresence>
                <div className="z-50 fixed inset-0 flex items-center justify-center md:px-6-safe lg:px-8">
                    <div onClick={trigger} className="fixed inset-0">
                        <motion.div initial="closed" animate="opened" exit="closed" variants={backdropVariants} className="absolute inset-0 bg-overlay-700" />
                    </div>
                    <motion.div initial="closed" animate="opened" exit="closed" variants={modalVariants} className="z-40 overflow-y-auto max-h-full">
                        <Container width={widths} className="max-h-full h-full md:h-auto md:my-4 py-4-safe sm:py-6-safe lg:py-8 bg-white md:rounded md:border md:border-gray-300 md:shadow">
                            {children}
                        </Container>
                    </motion.div>
                </div>
            </AnimatePresence>
        </Portal>
    );
};

export default Modal;
