import React from "react";
import { useAuth0 } from "./hooks/useAuth0";
import { SWRConfig } from "swr";
import { SWRFetcher } from "./utils/api";
import Home from "./views/Home";
import Login from "./views/Login";


const App = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) return <div></div>;

  return (
    <SWRConfig value={{ fetcher: SWRFetcher, revalidateOnFocus: false }}>
      <main>
        {isAuthenticated ?
          <Home /> :
          <Login />
        }
      </main>
    </SWRConfig>
  );
};

export default App;
