import React, { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from "react";
import { cx } from "../../../utils/cx";

const DropdownOptionsItem: ForwardRefRenderFunction<HTMLLIElement, HTMLAttributes<HTMLLIElement>> = ({ children, className = "", ...props }, ref) => (
    <li ref={ref} className={cx("py-4 pl-4 cursor-default", className)} {...props}>
        {children}
    </li>
);

export default forwardRef(DropdownOptionsItem);
