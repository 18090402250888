import React from "react";
import Modal from "../components/UI/Modal";
import TagLibrary from "./TagLibrary";

interface Props {
  showModal: boolean,
  trigger: () => void,
  tagCatalogue: any,
  tags: any,
  onSubmit: any
}

const TagLibraryModal: React.FC<Props> = ({ showModal, trigger, tagCatalogue, tags, onSubmit }) => {

  return (
    <Modal isOpen={showModal} trigger={trigger}>
      <div className="flex space-between">
        <div className="text-black mb-2-1/2 text-xl font-extrabold">Manage audience filters</div>
      </div>
      <div className="mt-4 bold">Search or use the categories to define your audience requirements.</div>
      <TagLibrary tagCatalogue={tagCatalogue} tags={tags} trigger={trigger} onSubmit={onSubmit} />
    </Modal>
  );
};

export default TagLibraryModal;
