import React, { useState } from "react";
import { LoadingSpinner } from "../components/UI/Loading";

interface Props {
  trigger: () => void,
  tagCatalogue: any,
  tags: any,
  onSubmit: any
}

const TagLibrary: React.FC<Props> = ({ trigger, tagCatalogue, tags, onSubmit }) => {
  const [searchKey, setSearchKey] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([] as any);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  const addCategory = (category: string) => {
    const catIndex = selectedCategories.findIndex((cat: any) => cat === category)
    if (catIndex !== -1) {
      let newSelectedCategories = selectedCategories;
      newSelectedCategories.splice(catIndex,1);
      setSelectedCategories(newSelectedCategories);
      forceUpdate();
    }
    else {
      let newSelectedCategories = selectedCategories ?? [];
      newSelectedCategories = selectedCategories.concat([category])
      setSelectedCategories(newSelectedCategories)
    }
  }

  const addTag = (tag: any) => {
    const tIndex = selectedTags.findIndex((t: any) => t === tag)
    if (tIndex !== -1) {
      let newSelectedTags = selectedTags;
      newSelectedTags.splice(tIndex,1);
      setSelectedTags(newSelectedTags);
      forceUpdate();
    }
    else {
      let newSelectedTags = selectedTags ?? [];
      newSelectedTags = selectedTags.concat([tag]);
      setSelectedTags(newSelectedTags);
    }
  }

  const submitTags = () => {
    onSubmit(selectedTags);
    setSelectedCategories([]);
    setSelectedTags([]);
    setSearchKey("");
    trigger();
  }

  const showCategories = () => {
    return (
      <div className="mt-3 rounded border border-gray-300">
        {tagCatalogue.availableCategories.map((category: string, index:number) => {
          return (
            <div onClick={() => addCategory(category)} className={`p-5 hover:bg-gray-50 hover:cursor-pointer ${selectedCategories.findIndex((cat: any) => cat === category) !== -1 ? "bg-white text-primary-500 font-bold" : "bg-gray-100"} ${index === 0 ? "rounded-t" : ""} ${index === tagCatalogue.availableCategories.length - 1 ? "rounded-b" : ""}`} key={`${category}`}>
              {category}
            </div>
          );
        })}
      </div>
    )
  }

  const showTags = () => {
    let filteredTags = [] as any;
    if (selectedCategories.length) {
      selectedCategories.forEach((category: any) => {
        tagCatalogue.tagsCatalogue[category].forEach((option: any) => {
          option.tags.forEach((tag:any) => {
            filteredTags.push(tag)
          })
        })
      })
      filteredTags = filteredTags.sort((a: any, b: any) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });
    } else {
      filteredTags = tags;
    }

    if (searchKey !== "") {
      filteredTags = filteredTags.filter((tag: any) => tag.name.toLowerCase().includes(searchKey.toLocaleLowerCase()));
    }

    return (
      <>
        {filteredTags.map((tag: any) => {
          return (
            <div onClick={() => addTag(tag)} className={`ml-8 mr-1 p-5 mb-3 flex flex-col border border-gray-300 rounded hover:bg-gray-50 hover:cursor-pointer ${selectedTags.findIndex((t: any) => t === tag) !== -1 ? "bg-white text-primary-500 font-bold" : "bg-gray-100"}`} key={`${tag.id}`} style={{"width": "490px"}}>
              {tag.name}
              <span className="text-xs mt-2">{tag.description}</span>
            </div>
          );
        })}
      </>
    )
  }

  const getContent = () => (
    <>
      <div className="flex mt-5 h-96" style={{"width": "740px"}}>
        <div className="overflow-y-auto">
            <input
              className="p-3 border border-gray-300 rounded text-black font-bold"
              key="search"
              value={searchKey}
              placeholder={"Search"}
              onChange={(e) => {e.preventDefault(); setSearchKey(e.target.value);}}
            />
            {showCategories()}
        </div>
        <div className="overflow-y-auto">
          {showTags()}
        </div>
      </div>
      {!!selectedTags.length && <div className="bg-gray-100 -mx-8 -mb-8 mt-8 rounded-b flex justify-center">
        <button className="p-4 mt-3 mb-3 text-white bg-primary-500 font-bold rounded" onClick={() => submitTags()}>Proceed with selection ({selectedTags.length})</button>
      </div>}
    </>
  )


  return (
    <>
      {!tagCatalogue?.availableCategories ?
        <div className="mt-10">
          <LoadingSpinner />
        </div>
        : getContent()}
    </>
  );
}

export default TagLibrary;

