import React from "react";

const UpIcon: React.FC<{  
  height?: number,
  width?: number
}> = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 16 10" fill="none"><path d="M2.4603 8.80893L7.97379 3.21833L13.5361 8.80893C13.9752 9.2542 14.6583 9.2542 15.0974 8.80893C15.5365 8.36366 15.5365 7.67102 15.0974 7.22575L8.90084 0.942512C8.36413 0.447769 7.58346 0.447768 7.09554 0.942512L0.898961 7.22575C0.459833 7.67102 0.459833 8.36366 0.898961 8.80893C1.33809 9.2542 2.02118 9.2542 2.4603 8.80893Z" fill="#505664"></path></svg>
);

export default UpIcon;
