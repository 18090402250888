import React, { FunctionComponent, HTMLAttributes } from "react";
import { cx } from "../../../utils/cx";

const widths = {
    1: "max-w-xl", // Single Wide Modal
    2: "max-w-2xl",
    3: "max-w-3xl",
    4: "max-w-4xl",
    5: "max-w-5xl",
    6: "max-w-6xl", // Double Wide Modal
    7: "max-w-7xl",
    100: "max-w-full"
};

export type ContainerProps = {
    width?: keyof typeof widths;
} & HTMLAttributes<HTMLDivElement>;

const Container: FunctionComponent<ContainerProps> = ({ className = "", width = 100, children, ...props }) => {
    return (
        <div className={cx("mx-auto px-4-safe sm:px-6-safe lg:px-8", className, widths[width])} {...props}>
            {children}
        </div>
    );
};

export default Container;
