import classnames from "classnames";
import { useSelect } from "downshift";
import React, { FunctionComponent } from "react";
import DropdownButton from "./Button";
import DropdownButtonIcon from "./ButtonIcon";
import DropdownLabel from "./Label";
import DropdownOptions from "./Options";
import DropdownOptionsItem from "./OptionsItem";

type Props = {
    placeholder: string;
    data: string[];
    id: string;
    isLoading?: boolean;
    disabled?: boolean;
    active?: boolean;
    onChange?: (value?: string | undefined) => void;
    initialSelectedItem?: string,
    onFilter?: (value: string | undefined) => string[];
};

const Dropdown: FunctionComponent<Props> = ({ placeholder, data, onChange, initialSelectedItem = "", id, isLoading = false, disabled = false, active = false }) => {
    const items = data;
    const onManagedChange = ({ selectedItem }: any) => {
        onChange && onChange(selectedItem);
    };

    const {
        getLabelProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex
    } = useSelect({
        id,
        initialSelectedItem,
        items,
        onSelectedItemChange: onManagedChange
    });

    return (
        <div className="relative">
            <div >
                <DropdownLabel inputLength={initialSelectedItem?.length || selectedItem?.length} {...getLabelProps()}>
                    {placeholder}
                </DropdownLabel>
                <div>
                    <DropdownButton disabled={disabled} isLoading={isLoading} isOpen={isOpen} active={active} selectedItem={selectedItem} {...getToggleButtonProps()} />
                    <DropdownButtonIcon isLoading={isLoading} isOpen={isOpen} />
                </div>
                <DropdownOptions isOpen={isOpen} >
                    <ul className={"overflow-y-scroll max-h-32 md:max-h-64"} {...getMenuProps()}>
                        {
                            items.map((item, index) => (
                                <DropdownOptionsItem
                                    className={classnames(
                                        "border-b font-normal first:border-t-0 last:border-b-0 border-gray-300 focus:text-primary-500 focus:underline focus:bg-gray-50 focus:outline-outline-primary-500",
                                        { "bg-gray-50 font-bold cursor-pointer text-primary-500": highlightedIndex === index }
                                    )}
                                    key={`${item}#${index}`}
                                    {...getItemProps({ item, index })}
                                >
                                    {item}
                                </DropdownOptionsItem>
                            ))
                        }
                    </ul>
                </DropdownOptions>
            </div>
        </div>
    );
}

export default Dropdown;

