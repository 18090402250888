import React, { useState } from "react";
import { LoadingSpinner } from "../components/UI/Loading";
import DownIcon from "../components/UI/Icons/DownIcon";
import UpIcon from "../components/UI/Icons/UpIcon";

interface Props {
  trigger: () => void,
  audienceData: any,
  loadingAudiences: boolean,
  onSubmit: any
}

const AudienceLibrary: React.FC<Props> = ({ trigger, audienceData, onSubmit, loadingAudiences }) => {
  const [searchKey, setSearchKey] = useState("");
  const [openIndex, setOpenIndex] = useState(-1);

  const printTags = (statements: any) => {
    let tagLine = "[";
    statements.forEach((statement: any, index: number) => {
      tagLine = tagLine.concat("{")
      Object.keys(statement).forEach((key: any, tagIndex: number) => {
        tagLine = tagLine.concat(`${key}: ${statement[key]}`)
        if (tagIndex !== Object.keys(statement).length - 1) tagLine = tagLine.concat(", ")
      })
      if (index !== statements.length - 1) tagLine = tagLine.concat("}, ")
      else tagLine = tagLine.concat("}")
    })
    tagLine = tagLine.concat("]")
    return tagLine;
  }

  const submitAudience = (name:string) => {
    onSubmit(name);
    setSearchKey("");
    trigger();
  }

  const getAudiences = () => {
    if (loadingAudiences) {
      return (
        <div className="mt-10">
          <LoadingSpinner />
        </div>
      )
    }
    if (audienceData.audienceList.length === 0) {
      return (
        <div className="mt-10 text-center">
          No audience definitions created yet for the selected client.
        </div>
      )
    }
    if (audienceData.audienceList.filter((audience: any) => audience.name.toLowerCase().includes(searchKey.toLocaleLowerCase())).length === 0) {
      return (
        <div className="mt-10 text-center">
          No matches found for the current search.
        </div>
      )
    }
    return (
      <div className="mt-3">
        {audienceData.audienceList.filter((audience: any) => audience.name.toLowerCase().includes(searchKey.toLocaleLowerCase())).map((audience: any, index:number) => {
          return (
            <div onClick={() => setOpenIndex(openIndex === index ? -1 : index)} className={`p-5 mb-3 flex flex-col rounded border border-gray-300 bg-gray-100 hover:bg-gray-50 hover:cursor-pointer`} key={`${audience.id}`}>
              <div className="flex">
                <p className="flex-4">{audience.name}</p>
                <p className="flex-2 text-center">{new Date(audience.createdOn).toLocaleDateString()}, {new Date(audience.createdOn).toLocaleTimeString()}</p>
                <p className="flex-2 text-center">{audience.createdBy}</p>
                <div className="flex-1 flex justify-end">{openIndex === index ? <UpIcon height={22.3} width={22.3} /> : <DownIcon height={22.3} width={22.3} />}</div>
              </div>
              {openIndex === index && <div>
                <p className="mt-4"><strong>Description:</strong> {audience.description ?? "N/A"}</p>
                <p><strong>Tags:</strong> {printTags(audience.audiences)}</p>
                <p><strong>Generated By:</strong> {audience.createdBy}, {new Date(audience.createdOn).toLocaleDateString()}</p>
                <button onClick={(e) => {e.stopPropagation(); submitAudience(audience.name)}} className="p-4 mt-3 text-white bg-primary-500 font-bold rounded hover:bg-primary-700 active:bg-primary-800">Select</button>
              </div>}
            </div>
          );
        })}
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col mt-5" style={{"width": "1000px"}}>
        <input
          className="p-3 border border-gray-300 rounded text-black font-bold"
          key="search"
          value={searchKey}
          placeholder={"Search"}
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <div className="overflow-y-auto">
          <div className="flex my-5">
            <p className="flex-4 text-center">Audience name</p>
            <p className="flex-2 text-center">Last updated</p>
            <p className="flex-2 text-center">Created by</p>
            <div className="flex-1" />
          </div>
          {audienceData && getAudiences()}
        </div>
      </div>
    </>
  )
}

export default AudienceLibrary;
