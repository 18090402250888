import React from "react";
import Modal from "../components/UI/Modal";
import AudienceLibrary from "./AudienceLibrary";

interface Props {
  showModal: boolean,
  trigger: () => void,
  audienceData: any,
  loadingAudiences: boolean,
  onSubmit: any
}

const AudienceLibraryModal: React.FC<Props> = ({ showModal, trigger, audienceData, loadingAudiences, onSubmit }) => {

  return (
    <Modal isOpen={showModal} trigger={trigger}>
      <div className="flex space-between">
        <div className="text-black mb-2-1/2 text-xl font-extrabold">Browse audience definitions</div>
      </div>
      <div className="mt-4 bold">Select or search for an audience definition below.</div>
      <AudienceLibrary audienceData={audienceData} trigger={trigger} onSubmit={onSubmit} loadingAudiences={loadingAudiences} />
    </Modal>
  );
};

export default AudienceLibraryModal;
