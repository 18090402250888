import React, { useEffect, useState } from "react";
import { useAuth0 } from "../hooks/useAuth0";
import api from "../utils/api";
import Dropdown from "../components/UI/Dropdown";
import UpIcon from "../components/UI/Icons/UpIcon";
import DownIcon from "../components/UI/Icons/DownIcon";
import AudienceDetails from "./AudienceDetails";
import AudienceMultiSelect from "./AudienceMultiSelect";
import AudienceLibraryModal from "./AudienceLibraryModal";

const Home = () => {
  const { logout, clients, token } = useAuth0();

  const [clientData, setClientData] = useState([] as any);
  const [allTags, setAllTags] = useState([] as any);
  const [tagCatalogue, setTagCatalogue] = useState({} as any)
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [savedAudienceData, setSavedAudienceData] = useState(null as any);
  const [selectedAudienceIndex, setSelectedAudienceIndex] = useState(-1);
  const [editMode, setEditMode] = useState("off");
  const [setOpen, setSetOpen] = useState(true);
  const [showAudienceLibrary, setModalVisibility] = useState(false);
  const [initialTags, setInitialTags] = useState([] as any);
  const [saving, setSaving] = useState(false);
  const [loadingAudiences, setLoadingAudiences] = useState(false);

  useEffect(() => {
    if (!token) return;

    const headers = {
      'Authorization': `Bearer ${token}`
    }
    api.get("/ssc/customers", {headers: headers})
      .then(response => {
        setClientData(response.data.filter((client: any) => clients.split(" ").includes(client.customerID)));
      })
      .catch(error => {})
    api.get("/ssc/audiences", {headers: headers})
      .then(response => {
        setTagCatalogue(response.data);
        let allTags = [] as any;
        response.data.availableCategories.forEach((category: any) => {
          response.data.tagsCatalogue[category].forEach((option: any) => {
            option.tags.forEach((tag:any) => {
              allTags.push(tag)
            })
          })
        })
        setAllTags(allTags.sort((a: any, b: any) => {
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        }));
      })
      .catch(error => {})
  }, [clients, token]);

  const clearValues = () => {
    setName("");
    setDescription("");
    setEditMode("off");
  };

  const getAudiences = (client: any) => {
    setLoadingAudiences(true);
    setSelectedAudienceIndex(-1);
    const customerID = clientData[clientData.findIndex((customer: any) => customer.clientDisplayName === client)].customerID;
    const headers = {
      'Authorization': `Bearer ${token}`
    }
    api.get(`/ssc/customers/${customerID}/audienceSelections`, {headers: headers})
      .then(response => {
        setSavedAudienceData(response.data);
        setLoadingAudiences(false);
      })
      .catch(error => {})
  };

  const chooseAudience = (name: any) => {
    clearValues();
    if (name !== "Create new audience") setSelectedAudienceIndex(savedAudienceData.audienceList.findIndex((audience: any) => audience.name === name));
  };

  const getAudienceDetails = () => {
    if (editMode === "edit" || editMode === "create" || editMode === "copy") {
      return (
        <>
          <p className="mt-5 text-xl font-bold">New audience</p>
          <label className="mt-3 block mb-2-1/2">Audience Name</label>
          <input onChange={(event) => setName(event.target.value)} value={name} className="rounded text-black mb-5 font-extrabold p-4 w-full border border-gray-300" type="text" id="string" />
          <label className="block mb-2-1/2">Audience Description</label>
          <input onChange={(event) => setDescription(event.target.value)} value={description} className="rounded text-black font-extrabold p-4 py-4 w-full border border-gray-300" type="text" id="string" />
        </>
      )
    }
  };

  const setEditing = (editMode: string) => {
    setEditMode(editMode);
    const audience = savedAudienceData.audienceList[selectedAudienceIndex];
    if (editMode === "copy") {
      setName(`Copy of ${audience.name}`);
      setDescription(audience.description);
    } else {
      setName(audience.name);
      setDescription(audience.description);
    }

    let newInitialTags = [] as any;
    audience.audiences.forEach((statement: any) => {
      let newStatement = [] as any;
      Object.keys(statement).forEach((id: string) => {
        let tag = allTags.find((tag: any) => tag.id === id);
        tag.value = statement[id];
        newStatement.push(tag);
      })
      newInitialTags.push(newStatement);
    })
    setInitialTags(newInitialTags);
  };

  const saveAudience = (audience: any) => {
    setSaving(true);
    const customerID = clientData[clientData.findIndex((client: any) => client.clientDisplayName === selectedClient)].customerID;
    const body = {
      "name": name === "" ? "Untitled" : name,
      "description": description === "" ? "N/A" : description,
      "audiences": audience
    }
    const headers = {
      'Authorization': `Bearer ${token}`
    }

    if (editMode === "edit") {
      const audienceId = savedAudienceData.audienceList[selectedAudienceIndex].id;
      api.put(`/ssc/customers/${customerID}/audienceSelections/${audienceId}`, body, {headers: headers})
        .then(response => {
          setEditMode("off");
          api.get(`/ssc/customers/${customerID}/audienceSelections`, {headers: headers})
            .then(res => {
              setSavedAudienceData(res.data);
              setSelectedAudienceIndex(res.data.audienceList.findIndex((audience: any) => audience.name === name));
            })
            .catch(error => {})
          setName("");
          setDescription("");
          setInitialTags([]);
          setSaving(false);
        })
        .catch(error => {})
    } else {
      api.post(`/ssc/customers/${customerID}/audienceSelections`, body, {headers: headers})
        .then(response => {
          setEditMode("off");
          api.get(`/ssc/customers/${customerID}/audienceSelections`, {headers: headers})
            .then(res => {
              setSavedAudienceData(res.data);
              setSelectedAudienceIndex(res.data.audienceList.findIndex((audience: any) => audience.name === name));
            })
            .catch(error => {})
          setName("");
          setDescription("");
          setSaving(false);
        })
        .catch(error => {})
    }
  };

  const cancel = () => {
    clearValues();
  }

  const getAudienceManagement = () => {
    if (editMode === "create" || editMode === "edit" || editMode === "copy") return <AudienceMultiSelect tagCatalogue={tagCatalogue} allTags={allTags} initialTags={editMode === "create" ? [] : initialTags} saveAudience={saveAudience} cancel={cancel} saving={saving} />;
    if (selectedAudienceIndex < 0 || selectedClient === "") return;
    const customerId = clientData[clientData.findIndex((customer: any) => customer.clientDisplayName === selectedClient)].customerID;
    return <AudienceDetails audience={savedAudienceData.audienceList[selectedAudienceIndex]} customerId={customerId} setEditing={setEditing} />;
  };

  return (
    <>
      <div className="flex">
        <div className="flex justify-between px-20 py-5 bg-gray-100 shadow z-50 fixed top-0 w-full h-21">
          <div />
          <button className="bg-gray-50 text-primary-500 font-extrabold sm:ml-auto px-5 py-2 border border-gray-300 rounded hover:bg-gray-100 active:text-primary-700 focus:underline focus:outline-none focus:border-primary-500" onClick={() => logout()}>Log out</button>
        </div>
        <div className="flex">
          <div className="mt-21 p-12 w-96 bg-gray-50" style={{"minHeight": "calc(100vh - 84px)"}}>
            <div className="">
              <div onClick={() => {setSetOpen(!setOpen);}} className="text-2xl pb-5 border-b border-gray-300 flex justify-between relative hover:cursor-pointer">
                <p className={setOpen ? "text-black font-bold" : ""}>Define Audience</p>
                {setOpen ? <UpIcon height={22.3} width={22.3} /> : <DownIcon height={22.3} width={22.3} />}
              </div>
              {setOpen && <div className="pt-5 pb-8 border-b border-gray-300">
                  <Dropdown data={clientData ? clientData.map((client:any) => client.clientDisplayName) : []} initialSelectedItem={selectedClient} placeholder="Select client(s)" id="client" onChange={(client: any) => {setSelectedClient(client); getAudiences(client);clearValues();}} key="ClientDropdown" />
                  {selectedClient && <div>
                    <p className="mt-4">Create/Browse Audiences</p>
                    <div className="flex">
                      <button className="p-4 mt-3 mr-4 bg-primary-500 text-white font-bold rounded hover:bg-primary-700 active:bg-primary-800" onClick={() => {chooseAudience("Create new audience"); setEditMode("create");setName("");setDescription("");setInitialTags([]);}}>Create</button>
                      <button className="p-4 mt-3 bg-primary-500 text-white font-bold rounded hover:bg-primary-700 active:bg-primary-800" onClick={() => setModalVisibility(true)}>Browse</button>
                    </div>
                  </div>
                  }
                  {getAudienceDetails()}
              </div>}
            </div>
            <div>
              <div className="text-2xl pb-5 m border-b border-gray-300 flex flex-col relative">
                <div className="mt-4 flex justify-between items-center hover:cursor-pointer">
                  <p>Manage Content (Coming Soon)</p>
                  <div style={{"width": "22.3px"}}><DownIcon height={22.3} width={22.3} /></div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-2xl pb-5 m border-b border-gray-300 flex flex-col relative">
                <div className="mt-4 flex justify-between items-center hover:cursor-pointer">
                  <p>Schedule Email/SMS (Coming Soon)</p>
                  <div style={{"width": "22.3px"}}><DownIcon height={22.3} width={22.3} /></div>
                </div>
              </div>
            </div>
          </div>
          {getAudienceManagement()}
        </div>
      </div>
      <AudienceLibraryModal audienceData={savedAudienceData} showModal={showAudienceLibrary} trigger={() => setModalVisibility(false)} onSubmit={chooseAudience} loadingAudiences={loadingAudiences} />
    </>
  );
};

export default Home;
